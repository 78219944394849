import type { EntitiesV2HistoryPostData } from "@/types/ls-api";
import { api } from "@/helpers/api/posts";

/** まだ投稿されていない投稿かどうか */
export function isNotYetPublished(post: EntitiesV2HistoryPostData): boolean {
    return post?.state === "INCOMPLETE" || post?.state === "READY";
}

/** 新規ファイル投稿かどうか */
async function isNewFilePost(post: EntitiesV2HistoryPostData): Promise<boolean> {
    // 子ファイルのステータスを集計して、新規投稿かどうかを判定する
    const results = await api.loadAllStatus(post);
    let oldPost = 0;
    results?.forEach((result) => {
        result?.details.forEach((detail) => {
            if (detail?.status !== "READY" && detail?.status !== "FAILED") {
                oldPost++;
            }
        });
    });
    return oldPost == 0;
}

/** 新規投稿かどうか */
async function isNewPost(post: EntitiesV2HistoryPostData): Promise<boolean> {
    if (post?.xlsxMode) {
        return await isNewFilePost(post);
    } else {
        // note: 2024/3現在は画面作成画面からの新規投稿の承認依頼のときはfileNamesが作成されないのでこれを利用している
        const count = (post?.fileNames ?? []).length;
        return count === 0;
    }
}

/** 新規投稿の承認要求かどうか */
export async function isNewRequest(post: EntitiesV2HistoryPostData): Promise<boolean> {
    const isNew = await isNewPost(post);
    return isNew && post?.state === "JUDGEMENTREQUEST";
}

/** 新規投稿のactionTypeを判定 */
export function determineNewActionType(
    post: EntitiesV2HistoryPostData,
    isRequestAuthorization: boolean
): string {
    if (isRequestAuthorization) {
        return post?.xlsxMode ? "v2-post-new-file-requested" : "v2-post-new-requested";
    }
    return post?.xlsxMode ? "v2-post-new-file-reserved" : "v2-post-new-reserved";
}

/** 投稿編集のactionTypeを判定 */
export async function determineEditActionType(
    post: EntitiesV2HistoryPostData,
    isRequestAuthorization: boolean
): Promise<string> {
    // 却下後の編集等の場合は新規投稿として扱う
    const isNew = await isNewPost(post);
    if (isNew) {
        return determineNewActionType(post, isRequestAuthorization);
    }
    if (isRequestAuthorization) {
        return post?.xlsxMode ? "v2-post-edit-file-requested" : "v2-post-edit-requested";
    }
    return post?.xlsxMode ? "v2-post-edit-file-reserved" : "v2-post-edit-reserved";
}

/** 投稿削除のactionTypeを判定 */
export async function determineDeleteActionType(
    post: EntitiesV2HistoryPostData,
    isRequestAuthorization: boolean
): Promise<string> {
    // FIXME: この条件分岐がbackendの仕様に基づくものなので、できればbackend側で判定するようにしたい
    if (post?.incompletePost != null && (post?.incompleteNew || post?.incompleteEdit)) {
        // 編集一時保存の場合は一時保存内容を戻す
        return "v2-post-delete-incomplete";
    } else if (
        post.incompleteNew ||
        post?.state == "DELETEFINISHED" ||
        post?.state == "READY" ||
        post?.state == "FAILED"
    ) {
        // 新規一時保存, DELETEFINISHED, READY, FAILEDは「指示ファイル削除」処理指示として扱う(backendの仕様)
        // ただしclientの仕様では DELETEFINISHED, FAILEDは削除ボタンが押せない
        return "v2-post-delete";
    }
    // 申請取り下げの場合
    if (post?.state == "JUDGEMENTREQUEST" || post?.state == "JUDGEMENTREJECT") {
        if (post?.judgementRequestIsDelete) {
            return post?.xlsxMode
                ? "v2-post-delete-file-request-withdrawn"
                : "v2-post-delete-request-withdrawn";
        }
        // 新規取り下げの場合は指示ファイルが削除されるが、↑の指示ファイル削除とは区別する
        const isNew = await isNewPost(post);
        if (isNew) {
            return post?.xlsxMode
                ? "v2-post-new-file-request-withdrawn"
                : "v2-post-new-request-withdrawn";
        }
        return post?.xlsxMode
            ? "v2-post-edit-file-request-withdrawn"
            : "v2-post-edit-request-withdrawn";
    }
    // 削除承認依頼の場合
    if (isRequestAuthorization) {
        return post?.xlsxMode ? "v2-post-delete-file-requested" : "v2-post-delete-requested";
    }
    // その他は一括削除処理指示として扱う(ファイル一括とそうでない投稿と区別をつける)
    return post?.xlsxMode ? "v2-post-delete-file-reserved" : "v2-post-delete-reserved";
}

/** 投稿再実行のactionTypeを判定 */
export function determineRetryActionType(post: EntitiesV2HistoryPostData): string {
    return post?.xlsxMode ? "v2-post-retry-file-reserved" : "v2-post-retry-reserved";
}

/** 投稿承認のactionTypeを判定 */
export async function determineApproveActionType(post: EntitiesV2HistoryPostData): Promise<string> {
    if (post.judgementRequestIsDelete) {
        return post?.xlsxMode ? "v2-post-delete-file-approved" : "v2-post-delete-approved";
    } else if (await isNewPost(post)) {
        return post?.xlsxMode ? "v2-post-new-file-approved" : "v2-post-new-approved";
    } else {
        return post?.xlsxMode ? "v2-post-edit-file-approved" : "v2-post-edit-approved";
    }
}

/** 投稿承認依頼却下のactionTypeを判定 */
export async function determineRejectActionType(post: EntitiesV2HistoryPostData): Promise<string> {
    if (post.judgementRequestIsDelete) {
        return post?.xlsxMode ? "v2-post-delete-file-rejected" : "v2-post-delete-rejected";
    } else if (await isNewPost(post)) {
        return post?.xlsxMode ? "v2-post-new-file-rejected" : "v2-post-new-rejected";
    } else {
        return post?.xlsxMode ? "v2-post-edit-file-rejected" : "v2-post-edit-rejected";
    }
}
