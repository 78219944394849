<template>
  <v-dialog v-model="confirmAutoBlock" width="700" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>重要な変更の確認</v-card-title>
      <v-card-text>
        <br />
        「変更提案を自動で元に戻す」機能をONにしようとしています。
        <br />
        この機能をONにするとGoogle Business Profile（GBP）の
        <b>ビジネスグループに含まれる全店舗</b>
        で
        <br />
        「店舗情報の変更提案」の行われた店舗の情報を1日に1回自動で{{
          appName
        }}が元に戻すようになります。
        <br />
        <br />
        注意1: {{ appName }}上で管理していない店舗も本機能の対象になります
        <br />
        <br />
        注意2:
        本機能でも元に戻せない変更提案が一部ございます。元に戻せない変更提案があった場合メール通知を行いますので手動でご対応ください
        <br />
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="isConsented"
          :label="dict.check_approve"
          color="primary"
          style="margin-left: 10px"
        />
        <v-spacer></v-spacer>
        <v-btn @click="confirmAutoBlock = false">キャンセル</v-btn>
        <v-btn
          :disabled="!isConsented"
          color="primary"
          @click="
            () => {
              confirmAutoBlock = false;
              submit();
            }
          "
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="confirmAllowAnyAspectRatio" width="700" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>重要な変更の確認</v-card-title>
      <v-card-text>
        <br />
        「Google Business
        Profile画像・動画の縦横比チェック」を常に行わない設定をONにしようとしています。
        <br />
        この機能をONにすると、投稿作成時にGBPの画像・動画の縦横比チェックが常に行われないまま投稿されるため、ご注意ください。
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="isConsentedAspectRatio"
          :label="dict.check_approve"
          color="primary"
          style="margin-left: 10px"
        />
        <v-spacer></v-spacer>
        <v-btn @click="confirmAllowAnyAspectRatio = false">キャンセル</v-btn>
        <v-btn
          :disabled="!isConsentedAspectRatio"
          color="primary"
          @click="
            () => {
              confirmAllowAnyAspectRatio = false;
              submit();
            }
          "
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <VeeForm ref="observer" as="div" data-testid="form">
    <div class="form-information">{{ dict.col_required }}</div>
    <Field v-slot="{ errors }" v-model="company.name" rules="required" :name="dict.col_name_title">
      <!-- 企業名 -->
      <label class="label">{{ dict.col_name_title }}</label>
      <v-text-field
        ref="name"
        v-model="company.name"
        data-testid="name"
        name="name"
        class="input-field"
        maxlength="64"
        :has-counter="false"
        :disabled="!canShowCompany"
        bg-color="#fff"
        hide-details
        outlined
        dense
        variant="outlined"
        density="compact"
      ></v-text-field>
      <p class="error-message">{{ errors[0] }}</p>
    </Field>

    <!-- GBP APIのトークンの種類 -->
    <o-field class="required-highlight" :label="dict.col_use_customer_gbp_token">
      <ul>
        <li>
          <o-radio
            v-model="company.useCustomerGbpToken"
            :native-value="false"
            name="useCustomerGbpToken"
            :disabled="!isNew"
          >
            {{ dict.col_use_customer_gbp_token_false }}
          </o-radio>
        </li>
        <li>
          <o-radio
            v-model="company.useCustomerGbpToken"
            :native-value="true"
            name="useCustomerGbpToken"
            :disabled="!isNew"
          >
            {{ dict.col_use_customer_gbp_token_true }}
          </o-radio>
        </li>
      </ul>
    </o-field>

    <!-- アカウント名 -->
    <o-field class="required-highlight" :label="dict.col_gmb_account_name_title">
      <template v-if="isNew">
        <span v-if="!company.useCustomerGbpToken">
          <Field
            v-slot="{ handleChange, value, errors }"
            v-model="company.gmbAccount"
            rules="required"
            :name="dict.col_gmb_account_name_title"
          >
            <v-autocomplete
              data-testid="gmb-account"
              name="name"
              :items="gmbAccounts"
              item-title="name"
              item-value="id"
              :multiple="false"
              persistent-hint
              :small-chips="false"
              :clearable="false"
              :disabled="!isNew || !canShowCompany"
              :model-value="value"
              @update:model-value="handleChange"
            ></v-autocomplete>
            <p class="error-message">{{ errors[0] }}</p>
          </Field>
        </span>
        <span v-else>
          {{ dict.col_use_customer_gbp_token_true_account_name }}
        </span>
      </template>
      <template v-else>
        <v-input :disabled="true">{{ company.gmbAccount }}</v-input>
      </template>
    </o-field>

    <!-- サイテーション設定 -->
    <o-field :label="dict.col_citation_title">
      <v-list class="d-flex flex-row">
        <v-list-item>
          <!-- オプト社員のみサイテーションオプションの設定が可能 -->
          <v-checkbox
            v-model="facebookSetting.enabled"
            data-testid="citation-facebook"
            :label="dict.col_citation_facebook"
            :disabled="!canShowCompany"
            color="primary"
            density="compact"
            hide-details
          />
        </v-list-item>
        <v-list-item>
          <v-checkbox
            v-model="instagramSetting.enabled"
            data-testid="citation-instagram"
            :label="dict.col_citation_instagram"
            :disabled="!canShowCompany"
            color="primary"
            density="compact"
            hide-details
          />
        </v-list-item>
        <v-list-item>
          <v-checkbox
            v-model="yplaceSetting.enabled"
            data-testid="citation-yplace"
            :label="dict.col_citation_yplace"
            :disabled="!canShowCompany"
            color="primary"
            density="compact"
            hide-details
          />
        </v-list-item>
        <v-list-item>
          <v-checkbox
            v-model="appleSetting.enabled"
            data-testid="citation-apple"
            :label="dict.col_citation_apple"
            :disabled="!canShowCompany"
            color="primary"
            density="compact"
            hide-details
          />
        </v-list-item>
      </v-list>
    </o-field>
    <!-- クチコミ取得可否 -->
    <o-field :label="dict.col_citation_review_setting_title">
      <v-list class="d-flex flex-row">
        <v-list-item>
          <v-checkbox
            v-model="yplaceSetting.enableReviewIntegration"
            data-testid="citation-yplace-review"
            :label="dict.col_citation_yplace"
            :disabled="!canShowCompany"
            color="primary"
            density="compact"
            hide-details
          />
        </v-list-item>
      </v-list>
    </o-field>

    <o-field v-if="appleSetting.enabled" :label="dict.citation_apple_businesses.title">
      <auto-complete-card
        ref="appleBrands"
        v-model="appleBrands"
        :items="appleBrandsItems"
        :label="appleBrandsSelectLabel"
        closable-chips
        style="width: 100%"
        :disabled="!canShowCompany"
        :loading="isLoadingApple"
        :chip-count="50"
        :max-count="50"
      />
    </o-field>

    <!-- ホスティング設定 -->
    <o-field v-show="canShowCompany || useReferersSetting" :label="dict.col_store_page_hosting">
      <v-list>
        <v-list-item v-show="canShowCompany">
          <!-- オプトの社員のみホスティング設定が可能-->
          <v-checkbox
            v-model="hosting"
            data-testid="hosting-enabled"
            :label="dict.col_store_page_hosting_enabled"
            density="compact"
            color="primary"
          />
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-text-field
            v-model="storePageHostingS3Path"
            :label="dict.col_store_page_hosting_s3_path"
            density="compact"
          ></v-text-field>
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-text-field
            v-model="storePageHostingPublicRootPath"
            :label="dict.col_store_page_hosting_public_root_url"
            density="compact"
          ></v-text-field>
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-text-field
            v-model="storePageHostingImagePath"
            :label="dict.col_store_page_hosting_image_path"
            density="compact"
          ></v-text-field>
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-text-field
            v-model="storePageHostingCloudfrontDistributionID"
            :label="dict.col_store_page_hosting_cloudfront_distribution_id"
            density="compact"
          ></v-text-field>
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-text-field
            v-model="storePageHostingCloudfrontDistributionPath"
            :label="dict.col_store_page_hosting_cloudfront_distribution_path"
            density="compact"
          ></v-text-field>
        </v-list-item>
        <v-list-item v-show="canShowCompany">
          <v-row>
            <v-col align-self="start" cols="2">
              <v-text-field
                v-model="hostingTrafficLimit"
                :label="dict.col_store_page_hosting_traffic_limit_setting"
                min="0"
                type="number"
                density="compact"
              ></v-text-field>
            </v-col>
            <v-col align-self="start" cols="2">
              <v-select
                v-model="hostingTrafficLimitUnit"
                label="単位"
                :items="['KB', 'MB', 'GB', 'TB']"
                density="compact"
                @update:model-value="hostingTrafficLimitUnitChanged"
              ></v-select>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col align-self="start" cols="2">
              <v-checkbox
                v-model="canEditStructuredID"
                :label="dict.col_store_page_hosting_structured_id"
                data-testid="canEditStructuredID"
                density="compact"
                color="primary"
              />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- 将来的に、ホスティング設定が有効な企業のみ認証情報ダウンローが有効になる-->
        <!-- <v-list-item v-show="company.options?.includes('hosting') && !canShowCompany">
          <v-btn>認証情報ダウンロード</v-btn>
        </v-list-item> -->

        <!-- 以下ホスティングが有効な企業のオーナー用の内容 -->
        <v-list-item v-show="useReferersSetting">
          <v-list-item-title>{{ dict.col_store_page_hosting_traffic_limit }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ convertUnit(company?.custom?.hostingTrafficPerMonth ?? 0) }} /
            {{ convertUnit(company?.custom?.hostingTrafficLimitPerMonth) }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-show="useReferersSetting">
          <Field
            v-slot="{ handleChange, errors }"
            v-model="referers"
            :rules="`chipMax:${referersSettings.max}`"
            :name="dict.col_store_page_hosting_referers"
          >
            <label class="label">{{ dict.col_store_page_hosting_referers }}</label>
            <v-combobox
              v-model="referers"
              name="referers"
              :placeholder="`${dict.col_placeholder_front}${referersSettings.max}${dict.col_placeholder_rear}`"
              :items="referersSettings.items"
              class="input-field"
              hide-no-data
              hide-selected
              hide-details
              bg-color="#fff"
              multiple
              small-chips
              solo
              dense
              variant="outlined"
              density="compact"
              @update:model-value="handleChange"
            >
              <template #selection="{ item }">
                <v-chip
                  closable
                  model-value
                  label
                  small
                  @click:close="removeKeywordReferers(item.value)"
                >
                  <span class="pr-2">
                    {{ item.title }}
                  </span>
                </v-chip>
              </template>
            </v-combobox>
            <p class="error-message">{{ errors[0] }}</p>
          </Field>
          <v-btn
            v-if="!showReferersNote"
            color="#CFD8DC"
            style="margin-top: 5px"
            prepend-icon="mdi-information"
            @click="showReferersNote = true"
          >
            <span style="padding-top: 2px">設定方法</span>
          </v-btn>
          <v-alert
            v-model="showReferersNote"
            :border="false"
            colored-border
            color="#CFD8DC"
            closable
            style="margin-top: 5px"
            max-width="1200px"
          >
            <v-card class="explanation">
              <v-card-title>特定のドメインを拒否したい場合</v-card-title>
              <v-card-text>拒否したいドメインをセットしてください (www.example.co.jp)</v-card-text>
            </v-card>
            <v-card class="explanation">
              <v-card-title>特定のページからのアクセスのみを許可する場合</v-card-title>
              <v-card-subtitle>2個の設定が必要です。</v-card-subtitle>
              <v-card-text>
                * をセットしてすべてのドメインからのアクセスを拒否してください (*)
              </v-card-text>
              <v-card-text>
                許可したいドメインとsuccessを#区切りでセットしてください (www.example.co.jp#success)
              </v-card-text>
            </v-card>
          </v-alert>
        </v-list-item>
      </v-list>
    </o-field>

    <!-- 変更提案を自動で元に戻す -->
    <o-field v-if="!company.useCustomerGbpToken" :label="dict.col_auto_block_title">
      <div class="d-flex flex-row align-center">
        <!-- オプト社員・オーナーは自動ブロック設定の設定が可能（ユーザーはこの画面に入れない） -->
        <v-checkbox
          v-model="autoBlock"
          data-testid="auto-block-gmb"
          :label="dict.col_auto_block_gmb"
          color="primary"
        />
        <ToolTipIcon :label="autoBlockInfo" position="right" class="ml-2 pb-5" />
      </div>
    </o-field>

    <!-- Google Business Profile画像・動画の縦横比チェック -->
    <o-field :label="dict.col_allow_any_aspect_ratio">
      <div class="d-flex flex-row align-center">
        <!-- オプト社員・オーナーは縦横比チェック設定の設定が可能（ユーザーはこの画面に入れない） -->
        <v-checkbox
          v-model="allowAnyAspectRatio"
          data-testid="allow-any-aspect-ratio"
          :label="dict.col_allow_any_aspect_checkbox_label"
          color="primary"
        />
        <ToolTipIcon :label="allowAnyAspectRatioInfo" position="right" class="ml-2 pb-5" />
      </div>
    </o-field>

    <!-- 投稿承認機能 -->
    <o-field :label="dict.col_post_approve_title">
      <div class="d-flex flex-row align-center">
        <!-- 投稿承認で承認者を手動選択するオプション -->
        <v-checkbox
          v-model="v2_post_approval_manual"
          data-testid="v2_post_approval_manual"
          :label="dict.col_post_approve_manual_select"
          color="primary"
        />
        <ToolTipIcon
          :label="dict.col_post_approve_manual_select_supplement"
          position="right"
          class="ml-2 pb-5"
        />
      </div>
    </o-field>

    <!-- オプション設定 -->
    <o-field v-show="canShowCompany" :label="dict.col_options_title">
      <v-list>
        <v-list-item>
          <!-- オプト社員のみオプション設定が可能 -->
          <!-- クチコミ分析を未契約状態に設定 -->
          <v-checkbox
            v-model="reviews_disabled"
            data-testid="option-reviews-disabled"
            :label="dict.col_options_reviews_disabled"
            color="primary"
            hide-details
          />
        </v-list-item>
      </v-list>
    </o-field>

    <!-- 対象検索ワード -->
    <Field
      v-slot="{ handleChange, errors }"
      v-model="company.keywords"
      :rules="`chipMax:${companyKeywordSettings.max}`"
      :name="dict.col_keywords_title"
    >
      <div v-show="canShowCompany" class="input-field">
        <div class="chip-counter">
          <label class="label">{{ dict.col_keywords_title }}</label>
          <span>{{ companyKeywordSettings.items.length }} / {{ companyKeywordSettings.max }}</span>
        </div>
        <v-combobox
          v-model="company.keywords"
          name="chipMax"
          class="text-combo"
          hide-no-data
          :items="companyKeywordSettings.items"
          hide-selected
          hide-details
          bg-color="#fff"
          :placeholder="`${dict.col_placeholder_front}${companyKeywordSettings.max}${dict.col_placeholder_rear}`"
          multiple
          small-chips
          solo
          dense
          variant="outlined"
          density="compact"
          @update:model-value="handleChange"
        >
          <template #selection="{ item }">
            <v-chip
              closable
              model-value
              label
              small
              @click:close="removeKeyword('keywords', item.value)"
            >
              <span class="pr-2">
                {{ item.title }}
              </span>
            </v-chip>
          </template>
        </v-combobox>
      </div>
      <p class="error-message">{{ errors[0] }}</p>
    </Field>

    <!-- 検索キーワードボリュームの集計キーワード -->
    <Field
      v-slot="{ handleChange, errors }"
      v-model="company.searchKeywordVolumeKeywords"
      :rules="`chipMax:${searchKeywordVolumeKeywordsSettings.max}`"
      :name="dict.col_search_keyword_volume_keywords_title"
    >
      <div v-show="canShowCompany" class="input-field">
        <div class="chip-counter">
          <label class="label">{{ dict.col_search_keyword_volume_keywords_title }}</label>
          <span>
            {{ searchKeywordVolumeKeywordsSettings.items.length }} /
            {{ searchKeywordVolumeKeywordsSettings.max }}
          </span>
        </div>
        <v-combobox
          v-model="company.searchKeywordVolumeKeywords"
          class="text-combo"
          :items="searchKeywordVolumeKeywordsSettings.items"
          hide-no-data
          hide-selected
          hide-details
          bg-color="#fff"
          :placeholder="`${dict.col_placeholder_front}${searchKeywordVolumeKeywordsSettings.max}${dict.col_placeholder_rear}`"
          multiple
          small-chips
          solo
          dense
          variant="outlined"
          density="compact"
          @update:model-value="handleChange"
        >
          <template #selection="{ item }">
            <v-chip
              closable
              model-value
              label
              small
              @click:close="removeKeyword('searchKeywordVolumeKeywords', item.value)"
            >
              <span class="pr-2">
                {{ item.title }}
              </span>
            </v-chip>
          </template>
        </v-combobox>
      </div>
      <p class="error-message">{{ errors[0] }}</p>
    </Field>

    <!-- 店舗のデフォルト半径 -->
    <Field
      v-slot="{ handleChange, value, errors }"
      v-model="formRadius"
      rules="numeric"
      :name="dict.col_default_radius"
    >
      <label class="label">{{ dict.col_default_radius }}</label>
      <v-text-field
        v-show="canShowCompany"
        ref="defaultRadius"
        data-testid="default-radius"
        name="defaultRadius"
        class="input-field"
        maxlength="4"
        :has-counter="false"
        :data-vv-as="dict.col_default_radius"
        :model-value="value"
        bg-color="#fff"
        hide-details
        outlined
        variant="outlined"
        density="compact"
        @update:model-value="handleChange"
      ></v-text-field>
      <p class="error-message">{{ errors[0] }}</p>
    </Field>

    <!-- 接続を許可するIPアドレス -->
    <Field
      v-slot="{ handleChange, errors }"
      v-model="company.ipWhitelist"
      rules="ipCIDR"
      :name="dict.col_ip_whitelist"
    >
      <label class="label">{{ dict.col_ip_whitelist }}</label>
      <v-combobox
        v-model="company.ipWhitelist"
        name="ipCIDR"
        :placeholder="`${dict.col_placeholder_front}${allowedIPSettings.max}${dict.col_placeholder_rear}`"
        :items="allowedIPSettings.items"
        class="input-field"
        hide-no-data
        hide-selected
        hide-details
        bg-color="#fff"
        multiple
        small-chips
        solo
        dense
        variant="outlined"
        density="compact"
        @update:model-value="handleChange"
      >
        <template #selection="{ item }">
          <v-chip
            closable
            model-value
            label
            small
            @click:close="removeKeyword('ipWhitelist', item.value)"
          >
            <span class="pr-2">
              {{ item.title }}
            </span>
          </v-chip>
        </template>
      </v-combobox>

      <p class="error-message">{{ errors[0] }}</p>
    </Field>

    <!-- 業態・業種 -->
    <div v-show="canShowCompany" class="input-field">
      <label class="label">{{ dict.col_business_type_list }}</label>
      <v-combobox
        v-model="company.businessTypeList"
        :items="businessTypeSettings.items"
        class="text-combo"
        hide-no-data
        hide-selected
        hide-details
        bg-color="#fff"
        multiple
        small-chips
        solo
        dense
        variant="outlined"
        density="compact"
      >
        <template #selection="{ item }">
          <v-chip
            closable
            model-value
            label
            small
            @click:close="removeKeyword('businessTypeList', item.value)"
          >
            <span class="pr-2">
              {{ item.title }}
            </span>
          </v-chip>
        </template>
      </v-combobox>
    </div>

    <!-- RPAで使うGBPアカウントの移行 -->
    <o-field v-show="canShowCompany" :label="dict.col_use_opt_gbp_account_title">
      <div class="d-flex flex-row align-center">
        <!-- optドメインのGBPアカウントを使う場合はチェックする -->
        <v-checkbox
          v-model="company.useOptGbpAccount"
          data-testid="use_opt_gbp_account"
          :label="dict.col_use_opt_gbp_account_label"
          color="primary"
        />
      </div>
    </o-field>

    <!-- 戻るボタンと更新ボタン -->
    <footer class="footer-buttons">
      <button v-show="canShowCompany" class="button is-primary" @click.prevent="goBack">
        {{ dict.button_go_back }}
      </button>
      <button v-if="isNew" class="button is-primary" data-testid="button_register" @click="submit">
        {{ dict.button_register }}
      </button>
      <button v-else class="button is-primary" data-testid="button_update" @click="submit">
        {{ dict.button_update }}
      </button>
    </footer>
    <div v-if="message" class="error-message">{{ message }}</div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </VeeForm>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
