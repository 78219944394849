<template>
  <div class="text-right" style="margin-top: 10px">
    <v-btn color="primary" density="comfortable" @click="exportRanking()">
      件数・評点ランキングXLSX
    </v-btn>
  </div>
  <div class="d-flex position-relative">
    <ReviewRanking
      ref="amount"
      class="w-50"
      title="店舗クチコミ件数ランキング"
      order-by="count"
      :data="data"
    />
    <ReviewRanking
      ref="ratio"
      class="w-50"
      title="店舗クチコミ評点ランキング"
      order-by="average"
      :data="data"
    />
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { api } from "@/helpers/api/review";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useSs } from "@/storepinia/ss";
import { type SelectedTarget } from "@/components/shared/store-selector.vue";
import ReviewRanking, { type Datum } from "./review-ranking.vue";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import wordDictionary from "@/word-dictionary";

export default defineComponent({
  components: { ReviewRanking },
  props: {
    poiGroupId: { type: Number, required: true },
    selectedTarget: { type: Object as PropType<SelectedTarget>, required: true },
    isWholePeriod: { type: Boolean, required: true },
    from: { type: String, required: true },
    to: { type: String, required: true },
  },
  data() {
    return {
      isUnmounted: false,
      loading: false,
      data: [] as Datum[],
      condition: "",
    };
  },
  unmounted() {
    this.isUnmounted = true;
  },
  methods: {
    async pageLoad() {
      // 店舗選択がない場合は何もしない
      if (this.selectedTarget.isNone) return;

      // 前回の取得条件と同じなら何もしない
      const condition =
        this.selectedTarget.toString() + `_${this.from}-${this.to}_${this.isWholePeriod}`;
      if (condition === this.condition) {
        return;
      }
      this.loading = true;
      try {
        const from = this.isWholePeriod ? "" : this.from;
        const to = this.isWholePeriod ? "" : this.to;
        const data = await api.getRatingRanking(this.poiGroupId, this.selectedTarget, from, to);
        if (this.isUnmounted) return; // 読み込み完了前にコンポーネントがアンマウントされた場合は以降の処理を行わない

        // データを整形する
        const stores = useIndexedDb().stores.stores;
        const ids = useSs().selectedStoreIds;
        const map: { [poiId: number]: Datum } = {};
        for (const id of ids) {
          map[id] = {
            index: 0,
            name: stores.find((s) => s.poiID === id)?.name ?? "",
            average: undefined,
            count: 0,
          };
        }
        for (const d of data.rankingItems ?? []) {
          map[d.poiID] = {
            ...d,
            index: 0,
            name: stores.find((s) => s.poiID === d.poiID)?.name ?? "",
          };
        }
        this.data = Object.values(map);
        this.condition = condition; // 取得条件を保存
      } finally {
        this.loading = false;
      }
    },
    async exportRanking() {
      const companyName = useIndexedDb().company.name;
      const targetName = this.getTargetName();
      const term = this.isWholePeriod
        ? "全期間"
        : `${this.from.replace("-", "/")} 〜 ${this.to.replace("-", "/")}`;
      const datetime = dayjs().format("YYYYMMDD");
      const fileName = this.isWholePeriod
        ? `${wordDictionary.service.name}-店舗クチコミ件数・評点ランキング-${companyName}-${datetime}-全期間.xlsx`
        : `${
            wordDictionary.service.name
          }-店舗クチコミ件数・評点ランキング-${companyName}-${datetime}-(${this.from.replace(
            "-",
            "_"
          )}-${this.to.replace("-", "_")}).xlsx`;

      let aoa = []; // Array of arrays
      const header1 = ["表示期間", "表示店舗"];
      aoa.push(header1);
      const row1 = [term, targetName];
      aoa.push(row1);
      aoa.push([]);
      let header2 = ["順位", "店舗", "件数"];
      aoa.push(header2);
      const items = this.data.map((d) => ({ ...d }));
      items.sort((a, b) => {
        const d1 = b.count - a.count;
        if (d1 < 0 || 0 < d1) return d1;
        const d2 = b.average - a.average;
        if (d2 < 0 || 0 < d2) return d2;
        return a.name.localeCompare(b.name);
      });
      items.forEach((item, i) => {
        item.index = i + 1;
        const row = [item.index.toString(), item.name, item.count.toString()];
        aoa.push(row);
      });
      const wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "店舗クチコミ件数ランキング");

      aoa = [];
      aoa.push(header1);
      aoa.push(row1);
      header2 = ["順位", "店舗", "平均評点"];
      aoa.push([]);
      aoa.push(header2);
      items.sort((a, b) => {
        const d1 = b.average - a.average;
        if (d1 < 0 || 0 < d1) return d1;
        const d2 = b.count - a.count;
        if (d2 < 0 || 0 < d2) return d2;
        return a.name.localeCompare(b.name);
      });
      items.forEach((item, i) => {
        item.index = i + 1;
        const avg = item.average === undefined ? "-" : item.average.toFixed(2);
        const row = [item.index.toString(), item.name, avg];
        aoa.push(row);
      });
      ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "店舗クチコミ評点ランキング");

      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      saveAs(new Blob([buf], { type: "application/octet-stream" }), fileName);
    },
    getTargetName(): string {
      if (this.selectedTarget.isAll) {
        return "全店舗";
      } else if (this.selectedTarget.isArea) {
        // const areaStores = [...useIndexedDb().areaStores];
        const selectedGroup = useIndexedDb().areaStores.find(
          (a) => a.areaID === this.selectedTarget.areaId
        );
        return selectedGroup?.name ?? "";
      } else {
        const selectedStore = useIndexedDb().stores.stores.filter(
          (s) => s.poiID === this.selectedTarget.poiIds[0]
        );
        return selectedStore[0]?.name ?? "";
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
