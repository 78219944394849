<template>
  <section :class="isMobile ? 'mobile-section' : 'section'">
    <!-- PC版検索条件 -->
    <div v-if="!isMobile" id="reviews-table" style="padding-bottom: 1rem">
      <div class="mb-2">
        <div class="float-left">
          <h2 class="graph-title">
            {{ title }}
          </h2>
        </div>
        <div class="text-right">
          <o-button
            variant="primary"
            size="small"
            :disabled="dataExportLoading"
            :loading="dataExportLoading"
            @click="xlsxExportSubmit()"
          >
            XLSXエクスポート
          </o-button>
          <ToolTipIcon label="上限：40000件" position="left" />
        </div>
      </div>
      <review-search-conditions
        :conditions="searchCondition"
        :reply-priority-sort-tooltip="replyPrioritySortTooltip"
        @update:model-value="searchCondition = $event"
        @search="search"
        @clear-search-condition="clearSearchCondition"
      />
    </div>
    <!-- スマホ版検索条件 -->
    <v-card v-else class="px-1 py-2 mb-3">
      <div class="mb-2">
        <span class="font-weight-bold px-1">{{ title }}</span>
      </div>
      <mobile-review-search-conditions
        :conditions="searchCondition"
        :reply-priority-sort-tooltip="replyPrioritySortTooltip"
        @update:model-value="searchCondition = $event"
        @search="search"
        @clear-search-condition="clearSearchCondition"
      />
    </v-card>

    <template v-if="hasNoReviews && !isLoading">
      <div data-testid="reviews-do-not-exist">ご指定の条件に該当するクチコミはありません。</div>
    </template>
    <template v-else>
      <!-- スマホ版 -->
      <div v-if="isMobile" class="mb-3">
        <div v-show="!isLoading" class="reply-count">
          <p class="attention">
            <v-icon size="20px">fas fa-circle-info</v-icon>
            <strong class="pl-1" style="font-size: 0.8rem">{{ reviewReplyCountInfo }}</strong>
          </p>
          <div
            v-show="searchedReviewType !== 'Rival'"
            data-testid="reviews-reply-own-total"
            class="reviews-reply-own-total"
          >
            自社: {{ reviewReplyRateOwn.total }}件 ({{ reviewReplyRateOwn.notReplied }}件未返信,
            返信率{{ reviewReplyRateOwn.replyRate }}%)
          </div>
        </div>
      </div>
      <!-- PC版 -->
      <div v-else style="display: flex; justify-content: space-between; align-items: flex-end">
        <p class="control">
          <!-- 返信ボタン -->
          <button
            v-if="canManage"
            :disabled="disableReply || featureToggle.getStatus('reply') === 'STOP'"
            data-testid="reviews-reply-button"
            class="button is-primary"
            @click="isComponentModalActive = true"
          >
            選択したクチコミに返信
          </button>
          <feature-toggle-icon :ft="featureToggle" part="reply" />
        </p>
        <div v-show="!isLoading" class="reply-count">
          <p class="attention">
            <v-icon>fas fa-circle-info</v-icon>
            <em>
              <strong>{{ reviewReplyCountInfo }}</strong>
            </em>
          </p>
          <div v-show="searchedReviewType !== 'Rival'" data-testid="reviews-reply-own-total">
            自社: {{ reviewReplyRateOwn.total }}件 ({{ reviewReplyRateOwn.notReplied }}件未返信,
            返信率{{ reviewReplyRateOwn.replyRate }}%)
          </div>
          <div v-show="searchedReviewType !== 'Own'">
            競合: {{ reviewReplyRateRival.total }}件 ({{ reviewReplyRateRival.notReplied }}件未返信,
            返信率{{ reviewReplyRateRival.replyRate }}%)
          </div>
        </div>
      </div>
      <v-dialog
        v-model="isComponentModalActive"
        :fullscreen="isMobile"
        scrollable
        :width="1200"
        class="reply-component-modal"
      >
        <modal-reply
          :reviews="reviewComments"
          @complete="submit"
          @close="isComponentModalActive = false"
        ></modal-reply>
      </v-dialog>
      <o-modal
        v-model:active="showReplyErrorModal"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        :width="1000"
        root-class="reply-modal"
      >
        <modal-reply-error
          :failed-reviews="failedReviews"
          :reply-error-message="replyErrorMessage"
          @close="showReplyErrorModal = false"
        ></modal-reply-error>
      </o-modal>
      <!-- PC版テーブル表示 -->
      <review-search-table
        v-if="!isMobile"
        ref="table"
        :reviews="reviews"
        :page="page"
        :reviews-per-page="reviewsPerPage"
        :total="total"
        :checked-reviews="checkedReviews"
        :is-loading="isLoading"
        @page-change="onPageChange"
        @sort="onSort"
        @update:checked-reviews="checkedReviews = $event"
      />
      <!-- スマホ版カード表示 -->
      <review-search-cards
        v-else
        :reviews="reviews"
        :page="page"
        :reviews-per-page="reviewsPerPage"
        :total="total"
        :checked-reviews="checkedReviews"
        :is-loading="isLoading"
        @page-change="onPageChange"
        @update:checked-reviews="checkedReviews = $event"
      />
      <!-- スマホ版クチコミ返信全チェック＆モーダル出現ボタン -->
      <v-bottom-navigation
        v-if="isMobile && !isLoading && !isDrawerOpened && canManage"
        data-testid="mobile-reviews-reply-navigation"
        height="110"
      >
        <div class="d-flex flex-column">
          <v-checkbox
            v-model="mobileAllCheck"
            data-testid="reviews-all-select-checkbox"
            class="mobile-all-select-checkbox mt-1"
            color="primary"
            density="compact"
            hide-details
            label="全てのクチコミを選択"
            @update:model-value="allCheck"
          />
          <button
            :disabled="disableReply || featureToggle.getStatus('reply') === 'STOP'"
            data-testid="reviews-reply-button"
            class="button is-primary mt-1 py-1"
            @click="isComponentModalActive = true"
          >
            選択したクチコミに返信
          </button>
          <feature-toggle-icon :ft="featureToggle" part="reply" />
        </div>
      </v-bottom-navigation>
    </template>
  </section>
</template>

<script lang="ts">
import { requiredAuth } from "@/helpers";
import { Component, Prop, Vue, Watch, Model, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";

import type {
  StorageReviewsSearchResponse,
  EntitiesBigQueryRawReview,
  EntitiesReviewReplyRates,
  StorageReviewsReplyResponse,
  StorageReplyFailedReview,
  EntitiesReviewReplyRate,
} from "@/types/ls-api";
import type {
  ReviewType,
  CommentExists,
  ReplyStatus,
  ReviewSearchCondition,
  ReviewResponseRow,
} from "./review-parameter";
import ModalReply from "./modal-reply.vue";
import ModalReplyError from "./modal-reply-error.vue";
import ReviewSearchConditions from "./review-search-conditions.vue";
import MobileReviewSearchConditions from "./mobile-review-search-conditions.vue";
import ReviewSearchTable from "./review-search-table.vue";
import ReviewSearchCards from "./review-search-cards.vue";
import wordDictionary from "@/word-dictionary";
import type { FeatureToggle } from "@/routes/FeatureToggle";
import FeatureToggleIcon from "@/components/shared/FeatureToggleIcon.vue";
import { getOperationLogParams } from "@/routes/operation-log";
import { getter } from "@/storepinia/idxdb";

type ReviewSearchParams = {
  sortBy: string;
  order: string;
  word: string;
  starRatings: string;
  reviewType: string;
  hitsPerPage: number;
  page: number;
  commentExists?: boolean;
  replied?: boolean;
};

@Component({
  components: {
    ModalReply,
    ModalReplyError,
    FeatureToggleIcon,
    ReviewSearchConditions,
    MobileReviewSearchConditions,
    ReviewSearchTable,
    ReviewSearchCards,
  },
  emits: ["review-search", "xlsx-export-submit"],
})
class ReviewReport extends Vue {
  get reviewComments(): any {
    return this.checkedReviews.map((cr) => cr.comment) ?? [];
  }

  get hasNoReviews(): boolean {
    if (this.isLoading) {
      return false;
    }

    if (this.reviews == null) {
      return false;
    }

    if (this.reviews.length !== 0) {
      return false;
    }
    return true;
  }

  get disableReply(): boolean {
    return !(this.checkedReviews?.length > 0 ?? false);
  }

  company = getter().company;
  stores = getter().stores;
  isDrawerOpened = getter().isDrawerOpened;
  canManage = getter().canManageReviewReply;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  @Prop({ type: String }) reportName: string;
  @Prop({ type: Boolean }) isMobile: boolean;

  @Prop({ type: Object }) reviewSearchResponse: Partial<StorageReviewsSearchResponse>;
  @Model({ name: "loading" }) isLoading!: boolean;
  @Prop({ type: Boolean }) dataExportLoading: boolean;
  @Prop({ type: Number }) reviewsPerPage: number;
  @Prop({ type: Object }) reviewReplyRates: EntitiesReviewReplyRates;

  get reviewReplyRateOwn(): EntitiesReviewReplyRate {
    return this.reviewReplyRates?.own ?? { total: 0 };
  }

  get reviewReplyRateRival(): EntitiesReviewReplyRate {
    return this.reviewReplyRates?.rival ?? { total: 0 };
  }

  serviceFullName = wordDictionary.service.fullName;

  get replyPrioritySortTooltip(): string {
    return `ナレッジパネルの表示回数・クチコミの表示順位・内容の与える印象を元に${this.serviceFullName}独自に算出した返信優先順度で並び替えます`;
  }

  reviewType: ReviewType = "Own";
  searchedReviewType: ReviewType = "Own";
  commentExists: CommentExists = "CommentUnspecified";
  replyStatus: ReplyStatus = "ReplyUnspecified";
  starRatings = [1, 2, 3, 4, 5];
  starRatingsChecked = [];
  word = "";
  sortByReplyPriority: boolean = false;
  page: number = 1;
  total: number = 0;
  reviewsSearchParams: any;

  get searchCondition(): ReviewSearchCondition {
    return {
      reviewType: this.reviewType,
      commentExists: this.commentExists,
      replyStatus: this.replyStatus,
      word: this.word,
      starRatingsChecked: this.starRatingsChecked,
      sortByReplyPriority: this.sortByReplyPriority,
    };
  }

  set searchCondition(value: ReviewSearchCondition) {
    this.reviewType = value.reviewType;
    this.commentExists = value.commentExists;
    this.replyStatus = value.replyStatus;
    this.word = value.word;
    this.starRatingsChecked = value.starRatingsChecked;
    this.sortByReplyPriority = value.sortByReplyPriority;
  }

  title: string = "クチコミ検索";
  reviewReplyCountInfo: string =
    "クチコミ機能契約以降はトストア上からの返信のみを返信数のカウント対象としています。";

  // レスポンス内のreviews配列の型
  checkedReviews: Array<ReviewResponseRow> = [];

  isComponentModalActive = false;
  showReplyErrorModal = false;

  reviews: Array<ReviewResponseRow> = [];
  failedReviews: StorageReplyFailedReview[] = [];
  failedReviewIds: string[] = [];

  featureToggle: FeatureToggle;

  replyErrorMessage = "";

  // スマホ版のクチコミ全選択制御用
  mobileAllCheck: boolean = false;

  // -- Lifecycle Hooks  --
  async created(): Promise<void> {
    this.featureToggle = this.$route.meta.featureToggle as FeatureToggle;
  }

  async search(
    baseParams?: Record<string, unknown>,
    justReplied: boolean = false,
    sorting: boolean = false
  ): Promise<void> {
    // クチコミ検索
    // 返信直後じゃなければページ1に戻す
    this.page = justReplied === false ? 1 : this.page;
    const params: ReviewSearchParams = {
      sortBy:
        (baseParams?.sortBy as string) ??
        (this.sortByReplyPriority ? "replyPriority" : "updateTime"),
      order: (baseParams?.order as string) ?? "desc",
      word: this.word,
      starRatings: this.starRatingsChecked.join(","),
      reviewType: this.reviewType ?? "Own",
      hitsPerPage: this.reviewsPerPage,
      // 返信直後ならばその時の表示ページで再検索する様にする
      page: justReplied && baseParams?.page ? (baseParams.page as number) : this.page - 1,
    };

    this.searchedReviewType = this.reviewType;

    // コメント有無で「指定なし」の場合はパラメータを指定しないことでコメントあり/なし両方が検索対象となる
    if (this.commentExists !== "CommentUnspecified") {
      params.commentExists = this.commentExists === "Commented";
    }

    // 返信状態で「指定なし」の場合はパラメータを指定しないことで返信あり/なし両方が検索対象となる
    if (this.replyStatus !== "ReplyUnspecified") {
      params.replied = this.replyStatus === "Replied";
    }

    const table: any = this.$refs.table;
    if (table) {
      if (!sorting) {
        // 列ソートによるsearchじゃない時(「検索」や「適用」ボタンクリックした時)は初期化してソート矢印消す
        table.currentSortColumn = null;
      }
    }

    this.reviewsSearchParams = params;
    this.$emit("review-search", params);
  }

  async submit(replyMessage: string): Promise<void> {
    this.isComponentModalActive = false;
    this.isLoading = true;
    this.failedReviews = [];
    await requiredAuth<StorageReviewsReplyResponse>(
      "put",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/reviews/reply`,
      getOperationLogParams(this.$route, "reply"),
      {
        reviewIDs: this.checkedReviews.map((cr) => cr.reviewID),
        Comment: replyMessage,
      }
    )
      .then(() => {
        this.addSnackbarMessages({
          text: "ご指定のクチコミに返信しました。",
          color: "success",
        });
      })
      .catch((e) => {
        // 400 想定外の置き換え文字検出
        // 409 http.StatusConflict（toSTORE外で返信・削除されていたため返信に失敗）
        if (e.response?.status === 400 || e.response?.status === 409) {
          this.failedReviews = e?.response?.data?.failedReviews;
          this.failedReviewIds = this.failedReviews.map((r) => r.review.reviewID);
          this.replyErrorMessage = e?.response?.data?.errorMessage;
          this.showReplyErrorModal = true;
        } else {
          this.addSnackbarMessages({
            text: "ネットワークに問題があり、返信に失敗した可能性があります。お手数ですが画面をリロードして返信できているかをご確認ください",
            color: "danger",
          });
        }
      });
    // 返信直後はソート状態とページを維持したまま再検索する
    await this.search(
      {
        sortBy: this.reviewsSearchParams?.sortBy,
        order: this.reviewsSearchParams?.order,
        page: this.reviewsSearchParams?.page,
      },
      true
    );
    this.isLoading = true;
  }

  @Watch("reviewSearchResponse")
  reviewsUpdate(val: StorageReviewsSearchResponse): void {
    // 検索時、ページ遷移時、表示期間変更時に呼ばれる
    this.total = val?.total ?? 0;

    this.reviews =
      val?.reviews?.map((rs) => {
        if (this.getStoreName(rs) !== "") {
          return {
            ...rs,
            storeName: this.getStoreName(rs),
          };
        }
      }) ?? [];
    this.reviews = this.reviews.filter((rs) => rs); // 店舗名を取得できなかったクチコミを省く（例: 古い競合店）

    // 返信失敗していた場合は返信対象のチェック状態を維持する
    this.checkedReviews = this.reviews.filter((r) => this.failedReviewIds.includes(r.reviewID));
    this.failedReviewIds = [];
  }

  @Watch("checkedReviews")
  checkAllChecked() {
    if (this.checkedReviews.length != this.reviews.length) {
      this.mobileAllCheck = false;
    }
  }

  getStoreName(review: EntitiesBigQueryRawReview): string {
    const store = this?.stores?.stores?.find((s) => s.poiID === review?.poiID);
    const rival = (store?.rivals ?? []).length > 0 ? store.rivals[0] : null;
    return (review?.isRival ? rival?.name : store?.name) ?? "";
  }

  clearSearchCondition() {
    this.reviewType = "Own";
    this.replyStatus = "ReplyUnspecified";
    this.commentExists = "CommentUnspecified";
    this.word = "";
    this.starRatingsChecked = [];
    this.sortByReplyPriority = false;
  }

  async onPageChange(page: number) {
    this.mobileAllCheck = false;
    this.page = page;
    if (this.reviewsSearchParams == null) {
      this.reviewsSearchParams = {
        hitsPerPage: this.reviewsPerPage,
        page: this.page - 1,
      };
    }
    this.reviewsSearchParams.page = this.page - 1;
    this.$emit("review-search", this.reviewsSearchParams);
  }

  onSort(sortBy: string, order: string) {
    if (!this.reviewsSearchParams) {
      this.reviewsSearchParams = {};
    }
    this.reviewsSearchParams.sortBy = sortBy;
    this.reviewsSearchParams.order = order;
    this.search({ sortBy, order }, false, true);
  }

  allCheck() {
    if (this.mobileAllCheck) {
      this.checkedReviews = [...this.reviews];
    } else {
      this.checkedReviews = [];
    }
  }

  async xlsxExportSubmit(): Promise<void> {
    const params: any = {
      sortBy: this.reviewsSearchParams?.sortBy ?? "updateTime",
      order: this.reviewsSearchParams?.order ?? "desc",
      word: this.word,
      starRatings: this.starRatingsChecked.join(","),
      reviewType: this.reviewType ?? "Own",
    };

    if (this.commentExists !== "CommentUnspecified") {
      params.commentExists = this.commentExists === "Commented";
    }

    if (this.replyStatus !== "ReplyUnspecified") {
      params.replied = this.replyStatus === "Replied";
    }
    this.$emit("xlsx-export-submit", params);
  }
}
export default toNative(ReviewReport);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/button.scss" as button;

.mobile-section {
  background-color: color.$base-main-color;
}

.mobile-tooltip-icon {
  :deep(i) {
    padding-bottom: 0.25rem;
  }
}

.reply-count {
  margin-bottom: 2px;
  & > div {
    text-align: right;
  }
}
.attention {
  margin: 0 0 0.5em 0;
  .v-icon {
    vertical-align: middle;
  }
  em {
    vertical-align: middle;
    font-style: normal;
  }
  strong {
    color: color.$graph-title;
  }
}

.flex {
  flex: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.graph-title {
  color: color.$graph-title;
  font-weight: bold;
  font-size: 20px;
  flex: 1;
}

.reply-component-modal,
.reply-modal {
  z-index: var(--z-index-modal);
}

:deep(.v-bottom-navigation) {
  // 少し背景の要素を透けさせる
  background-color: rgba(255, 255, 255, 0.85);
}
.mobile-all-select-checkbox {
  :deep(label) {
    opacity: unset;
  }
}

.reviews-reply-own-total {
  font-size: 0.8rem;
}
</style>
